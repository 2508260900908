import React, { Fragment, useEffect, useState } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "black";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "black",
    borderBottomWidth: 1,

    // alignItems: "center",
    height: "auto",
    // fontStyle: "bold",
  },
  description: {
    width: "40%",
    paddingTop: 5,
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    paddingRight: 2,
    heigth: "100%",
  },
  basePrice: {
    width: "20%",
    paddingTop: 5,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  qty: {
    width: "10%",
    paddingTop: 5,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    paddingRight: 8,
  },
  rate: {
    width: "10%",
    paddingTop: 5,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    // paddingRight: 8,
    fontFamily: "Inter",
  },
  amount: {
    width: "20%",
    paddingTop: 5,
    textAlign: "center",
    paddingRight: 8,
    fontFamily: "Inter",
  },
});

const InvoiceTableRow = ({ items, order }) => {
  const rows = items?.map((item, index) => {
    return (
      <View style={styles.row} key={index}>
        <Text style={styles.description}>{item.name}</Text>
        <Text style={styles.basePrice}>{item.basePrice}</Text>
        <Text style={styles.qty}>{item.quantity}</Text>
        <Text style={styles.rate}>
          {parseFloat(item?.discount).toFixed(2) ?? 0}
        </Text>
        <Text style={styles.amount}>
          {parseFloat(item?.total).toFixed(2) ?? 0}
        </Text>
      </View>
    );
  });
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
