import styles from "./BillDetailReplacement.module.css";
import useCartTotals from "../../hooks/useCartTotal";
import { LuCalendarCheck2 } from "react-icons/lu";
import { MdOutlineAccessTime } from "react-icons/md";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import TaxDisplayPopup from "../elements/common/TaxDisplayPopup";
import { showOrderType } from "../../constants";
import { Tooltip } from "react-tooltip";
import CancelOrder from "../CancelOrder";
import { cancelOrder } from "../../services/ordersService";
import CancelOrderConfirmation from "../CancelOrder/CancelOrderConfirmation";
import { Button } from "../elements/Button";
import API from "../../services/API";
import useToast from "../../utils/toast/useToast";
import {
  calculateNonTaxableItemsTaxes,
  calculateNonTaxableItemsTotal,
} from "../../utils/helperFunction/taxFunction";
const BillDetail = ({ list, orderDetail, orderType, setOrderDetail }) => {
  const { total, totalItemPrice, taxes } = useCartTotals(
    list,
    orderDetail?.payment?.discount
  );
  const [showTaxes, setShowTaxes] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [cancelOrderReason, setCancelOrderReason] = useState("");
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [cancelSuccessfull, setCancelSuccessfull] = useState(false);
  const resId = localStorage.getItem("restaurantId");
  const toast = useToast();

  const handleCancel = () => setShowCancelPopup(true);
  const handleClose = () => setShowCancelPopup(false);

  const handleCancelOrder = async () => {
    const res = await cancelOrder({
      resId: resId,
      orderId: orderDetail._id,
      cancelOrderReason: cancelOrderReason,
    });
    if (res) {
      setCancelSuccessfull(true);
      setShowCancelConfirmation(false);
    }
  };
  const updateOrder = async (orderId) => {
    try {
      const response = await API.patch(`orders/${orderId}`, {
        paymentDoneByUser: true,
      });
      if (response?.data?.paymentDoneByUser) {
        setOrderDetail(response?.data);
      }
    } catch (error) {
      toast.showErrorToast("Some error occured please try again.");
      console.log(error);
    }
  };

  useEffect(() => {
    const tax = +orderDetail?.total - +orderDetail?.totalWithoutTax;

    // const itemIotal =
    //   (orderDetail.total * 100) /
    //   (100 + tax);
    // const taxes =
    //   ((orderDetail?.taxes?.cgst + orderDetail?.taxes?.sgst) / 100) * itemIotal;
  }, [orderDetail]);
  return (
    <div>
      <div className="bill-details py-2 ">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className={`fw-normal mx-1 h4 py-1 ${styles.billdetail}`}>
            {" "}
            <span>BILL DETAILS</span>
          </h6>
          <span
            className="d-flex align-items-center justify-content-center px-2 float-end"
            style={{
              color: "#FFF",
              backgroundColor: "#99a123",
              borderRadius: 5,
            }}
          >
            {" "}
            {showOrderType[orderType] ?? "df"}
          </span>
        </div>
        <div className={`${styles.details} border px-3 mt-1`}>
          <p
            className={`${styles.tablename} fs-5`}
            style={{ color: "var(--primary-color)" }}
          >
            {orderDetail?.tableName || orderDetail?.tableNumber}
          </p>
          <div className={`${styles.tablename} fs-6`}>
            <span className="mx-2">
              <LuCalendarCheck2 size={18} /> {orderDetail.orderDate}
            </span>

            <span>
              <MdOutlineAccessTime size={18} /> {orderDetail.orderTime}
            </span>
          </div>
          <hr style={{ backgroundColor: "rgba(142, 142, 142, 0.59)" }} />
          <div className={`${styles.tablename}`}>
            <div className="d-flex flex-row justify-content-between">
              <div>Item Total</div>
              <div>₹{totalItemPrice}</div>
            </div>
            {orderDetail?.payment?.discount > 0 && (
              <div className="d-flex flex-row justify-content-between">
                <div>Discount</div>
                <div>{`₹${orderDetail?.payment?.discount}`}</div>
              </div>
            )}
            {Boolean(taxes) ? (
              <div className="d-flex flex-row justify-content-between my-2">
                <div onClick={() => setShowTaxes(true)}>
                  Taxes <BsFillInfoCircleFill size={17} color="grey" />
                </div>
                <div>₹{taxes.toFixed(2)}</div>
              </div>
            ) : (
              <div className="d-flex flex-row justify-content-between my-2">
                <div>Taxes</div>
                <div>NA</div>
              </div>
            )}
            {Math.abs(orderDetail?.payment?.roundedOff) > 0 && (
              <div className="d-flex flex-row justify-content-between my-2">
                <div>Round off</div>
                <div>{(orderDetail?.payment?.roundedOff).toFixed(2)}</div>
              </div>
            )}
            {showTaxes && (
              <TaxDisplayPopup
                amount={
                  orderDetail?.payment?.discount
                    ? totalItemPrice - orderDetail?.payment?.discount
                    : totalItemPrice
                }
                taxes={taxes}
                setShowTaxes={setShowTaxes}
                showTaxes={showTaxes}
              />
            )}
          </div>
          <hr style={{ backgroundColor: "rgba(142, 142, 142, 0.59)" }} />
          <h6 className="fw-bold mb-0">
            Total Bill
            <span className="float-end  " style={{ color: "#fa4a0c" }}>
              ₹
              {orderDetail.orderStatus === "delivered" || orderDetail?.total
                ? orderDetail?.total - (orderDetail?.payment?.discount ?? 0)
                : 0}
            </span>
          </h6>
          <h6 className="fw-bold mb-0">
            {orderDetail?.payment !== null && orderDetail?.payment?.paid
              ? "You Paid"
              : orderDetail.orderStatus === "cancelled"
              ? "Total Amt."
              : ""}
            {(orderDetail?.payment && orderDetail?.payment?.paid) ||
            orderDetail?.orderStatus === "cancelled" ? (
              <span className="float-end" style={{ color: "#fa4a0c" }}>
                ₹
                {Math.round(
                  orderDetail?.payment?.paid
                    ? orderDetail?.payment?.paid
                    : orderDetail?.total
                )}
              </span>
            ) : (
              !orderDetail?.payment?.due &&
              !orderDetail?.payment?.waivedOff && (
                <span className="float-end" style={{ color: "#dc3545" }}>
                  {orderDetail?.paymentDoneByUser === undefined ||
                  orderDetail.paymentDoneByUser === false ? (
                    "Unpaid"
                  ) : (
                    <div className="d-flex justify-content-center align-items-center gap-1">
                      <BsFillInfoCircleFill
                        size={17}
                        color="grey"
                        data-tooltip-id="restaurant-payment-awaiting"
                      />
                      <h6
                        // className={`${styles.processStatus}`}
                        className="d-flex justify-content-center align-items-center gap-1 mb-0"
                        style={{
                          // color: "white",
                          // backgroundColor: "rgb(6, 147, 17)",
                          whiteSpace: "nowrap",
                        }}
                      >
                        You marked as paid.
                      </h6>
                      <Tooltip id="restaurant-payment-awaiting" place="right">
                        <div className="fw-normal">
                          You have paid the amount.
                        </div>
                        <div className="fw-normal">
                          Confirmation from restaurant is pending.
                        </div>{" "}
                      </Tooltip>
                    </div>
                  )}
                </span>
              )
            )}
          </h6>
          {Number.isFinite(orderDetail?.payment?.waivedOff) &&
            orderDetail?.payment?.waivedOff !== 0 && (
              <h6 className="fw-bold mb-0 ">
                Waived Off{" "}
                {/* <BsFillInfoCircleFill
                  size={17}
                  color="grey"
                  data-tooltip-id="waived-off"
                /> */}
                <span className="float-end  " style={{ color: "#fa4a0c" }}>
                  ₹{orderDetail?.payment?.waivedOff}
                </span>
              </h6>
            )}{" "}
          <Tooltip id="waived-off" place="right">
            {" "}
            {orderDetail?.payment?.waivedOffReason}
          </Tooltip>
          {Number.isFinite(orderDetail?.payment?.due) &&
            orderDetail?.payment?.due !== 0 && (
              <h6 className="fw-bold mb-0 ">
                Due Amount
                <span className="float-end  " style={{ color: "#fa4a0c" }}>
                  ₹{orderDetail?.payment?.due}
                </span>
              </h6>
            )}
        </div>
      </div>{" "}
      {orderDetail.orderStatus === "received" ? (
        <div className="text-end d-flex justify-content-end gap-2 py-2">
          <button className="btn btn-danger" onClick={handleCancel}>
            Cancel Order
          </button>
        </div>
      ) : null}
      {showCancelPopup ? (
        <CancelOrder
          showPopup={showCancelPopup}
          handleClose={handleClose}
          setCancelOrderReason={setCancelOrderReason}
          setShowCancelConfirmation={setShowCancelConfirmation}
          cancelSuccessfull={cancelSuccessfull}
          setCancelSuccessfull={setCancelSuccessfull}
        />
      ) : null}
      {showCancelConfirmation ? (
        <CancelOrderConfirmation
          showPopup={showCancelConfirmation}
          handleCancelOrder={handleCancelOrder}
          setShowCancelConfirmation={setShowCancelConfirmation}
        />
      ) : null}
      {/* {cancelSuccessfull || orderDetail.orderStatus === "cancelled" ? (
        <div className="max-w-lg mx-auto mt-8 p-4">
          <div className="bg-red-50 p-4 rounded-lg border border-red-200">
            <h2 className="text-red-900 text-lg font-semibold mb-2 text-danger">
              {orderDetail.orderStatus === "cancelled" && !cancelSuccessfull
                ? "Order Cancelled"
                : "Order Cancelled Successfully"}
            </h2>
            <p className="text-green-700">
              Your order has been cancelled. We hope to serve you again soon!
            </p>
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

export default BillDetail;
